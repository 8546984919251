import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, LinearProgress, Paper, Stack, TextField, Typography } from "@mui/material";
import { useState, type ChangeEvent, FormEvent, useContext } from "react";
import { green } from "@mui/material/colors";
import SubmitButton from "../components/SubmitButton";
import { useNavigate } from "react-router-dom";
import { Alert, State, emailRegex } from "./divine-constants";
import { api } from "../apiConfig";
import axios from "axios";

export function ForgotPassword() {
	const [email, setEmail] = useState("");
	const [validations, setValidations] = useState({
		email: " ",
	});
	const [dialogDisplay, setDialogDisplay] = useState(false);
	const [progress, setProgress] = useState(false);
	const {setAlert} = useContext(Alert);
	const navigate = useNavigate();

	function updateFormData(event: ChangeEvent<HTMLInputElement>) {
		setEmail(event.target.value);
		if(Object.values(validations).some(it => it !== " ")) {
			setValidations(curr => ({...curr, [event.target.name]: " "}));
		}
	}

	function validation() {
		let isValid = true;
		if (email.trim() === "") {
			isValid = false;
			setValidations(curr => ({...curr, email: "Email is required."}))
		} else if (!emailRegex.test(email)){
			isValid = false;
			setValidations(curr => ({...curr, email: "Invalid Email address"}))
		} else
			setValidations(curr => ({...curr, email: " "}));
		return isValid;
	}

	async function onSubmit(event: FormEvent<HTMLFormElement>) {
		setProgress(true);
		event.preventDefault();
		if(!validation()) {
			setProgress(false);
			return;
		};
		api().post("api/admin_otp_send", {
			email: email
		}).then(res => {
			if(res.status === 200 && res.data === 'Otp sent Successfully') {
				setDialogDisplay(true);
			}
			
		}).catch(() => {
			setAlert({
				message: "Invalid credentials.",
				show: true,
				severity: "error"
			});
		}).finally(() => {
			setProgress(false);
		});
	}

 	return (
		<Box component="div" style={{
			width: "100vw",
			height: "100vh",
			position: "fixed",
			top: 0,
			display: "grid",
			overflow: "auto",
			placeItems: "center",
			zIndex: 1201,
			backgroundColor: green["50"]
		}}>
			<Paper style={{
				width: "40%",
				minWidth: "275px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: 0,
				marginInline: "auto",
				padding: "1rem 2rem 1.5rem",
			}} component="form" elevation={2} onSubmit={onSubmit} noValidate>
				{progress && <LinearProgress />}
				<img 
					src="/logo192.png" 
					alt="logo" 
					style={{
						width: "50%",
						marginInline: "auto",
						minWidth: 150,
						display: "block"
					}} 
				/>
				<br />
				<Typography variant="subtitle1" color={green["800"]} style={{opacity: "0.5"}}>Type your email for verification.</Typography>
				<TextField 
					value={email}
					error={Boolean(validations.email.trim())}
					name="email"
					type="text"
					placeholder="example@service.com"
					label="Email"
					onChange={updateFormData}
					helperText={validations.email}
					InputLabelProps={{shrink: undefined}}
				/>
				<Stack direction="row" spacing={2} ml="auto">
					<Button onClick={() => navigate("/login")} color="inherit">Cancel</Button>
					<SubmitButton text="Submit" />
				</Stack>
				<Verification email={email} dialogState={[dialogDisplay, setDialogDisplay]} />
			</Paper>
		</Box>
	)
}

function Verification(props: {email: string, dialogState: [isDialogOpen: boolean, setIsDialogOpen: State<boolean>]}) {
	const [otp, setOtp] = useState("");
	const {setAlert} = useContext(Alert);
	const [progress, setProgress] = useState(false);
	const navigate = useNavigate();
	function handleChange(event: ChangeEvent<HTMLInputElement>) {
		if(Number(event.target.value) >= 0 && event.target.value.length <= 6) {
			setOtp(event.target.value.trim());
		}
	}
	function submitOTP() {
		setProgress(true);
		api().post('api/admin_verify_otp', {
			otp: otp,
			email: props.email
		}).then(res => {
			if(res.status === 200 && res.data.message === "verify successfully") {
				sessionStorage.setItem("temporaryToken", res.data.token);
				navigate("/reset-password");
				props.dialogState[1](false);
			}
		}).catch(err => {
			setAlert({
				message: "Unauthorized",
				severity: "error",
				show: true
			})
		}).finally(() => {
			setProgress(false);
		});
	}
	function resendOTP() {
		api().post("api/admin_otp_send", {
			email: props.email
		}).catch(err => {
			setAlert({
				message: "Invalid credentials.",
				show: true,
				severity: "error"
			});
		})
	}
	function handleClose(_: {}, reason: "backdropClick" | "escapeKeyDown") {
		if(reason === "backdropClick")
			return;
			props.dialogState[1](false)
	}
	return (
		<Dialog keepMounted={false} open={props.dialogState[0]} onClose={handleClose}>
			{progress && <LinearProgress />}
			<DialogTitle>Verification</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Enter the 6-digit one-time-password sent to your email.
				</DialogContentText>
				<TextField
					variant="standard"
					label="Code"
					name="verification code"
					aria-label="verification"
					value={otp}
					onChange={handleChange}
					fullWidth
				/>
			</DialogContent>
			<DialogActions>
				<Button size="small" onClick={resendOTP} color="info">Resend</Button>
				<Button size="small" onClick={submitOTP}>Next</Button>
			</DialogActions>
		</Dialog>
	)
}

export function ResetPassword() {
	const [formData, setFormData] = useState({
		newPassword: "", confirmPassword: ""
	});
	const [validations, setValidations] = useState({
		newPassword: " ", confirmPassword: " "
	});
	const [progress, setProgress] = useState(false);
	const {setAlert} = useContext(Alert);
	const navigate = useNavigate();

	function updateFormData(event: ChangeEvent<HTMLInputElement>) {
		setFormData(curr => ({...curr, [event.target.name]: event.target.value}));
		if(Object.values(validations).some(it => it !== " ")) {
			setValidations(curr => ({...curr, [event.target.name]: " "}));
		}
	}

	function validation(): boolean {
		let isValid = true;
		if(formData.newPassword.trim() === "") {
			isValid = false;
			setValidations(curr => ({...curr, newPassword: "Please enter a new password"}));
		} else if(formData.newPassword.trim().length < 8) {
			isValid = false;
			setValidations(curr => ({...curr, newPassword: "Password must be atleast 8 characters"}));
		} else setValidations(curr => ({...curr, newPassword: " "}));
		if(formData.newPassword !== formData.confirmPassword) {
			isValid = false;
			setValidations(curr => ({...curr, confirmPassword: "New Password and confirmation password must be same"}))
		} else setValidations(curr => ({...curr, confirmPassword: " "}));
		return isValid
	}

	async function onSubmit(event: FormEvent<HTMLFormElement>) {
		setProgress(true);
		event.preventDefault();
		if(!validation()) {
			setProgress(false);
			return;
		};
		axios.post("api/admin_change_password", {
			password: formData.newPassword
		}, {
			headers: {
				Authorization: "Bearer " + sessionStorage.getItem("temporaryToken")
			}
		}).then(res => {
			if(res.data.message === "reset sucessfully") {
				setAlert({
					message: "Password Reset Successfully",
					show: true,
					severity: "success"
				});
				navigate("/login");
				sessionStorage.clear();
			}
		}).catch(() => {
			setAlert({
				message: "Invalid credentials.",
				show: true,
				severity: "error"
			});
		}).finally(() => {
			setProgress(false);
		});
	}
	return (
		<Box component="div" style={{
			width: "100vw",
			height: "100vh",
			position: "fixed",
			top: 0,
			display: "grid",
			overflow: "auto",
			placeItems: "center",
			zIndex: 1201,
			backgroundColor: green["50"]
		}}>
			<Paper style={{
				width: "40%",
				minWidth: "275px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: 0,
				marginInline: "auto",
				padding: "1rem 2rem 1.5rem",
			}} component="form" elevation={2} onSubmit={onSubmit} noValidate>
				{progress && <LinearProgress />}
				<img 
					src="/logo192.png" 
					alt="logo" 
					style={{
						width: "50%",
						marginInline: "auto",
						minWidth: 150,
						display: "block"
					}} 
				/>
				<br />
				<TextField 
					value={formData.newPassword}
					error={Boolean(validations.newPassword.trim())}
					name="newPassword"
					type="password"
					label="New password"
					onChange={updateFormData}
					helperText={validations.newPassword}
				/>
				<TextField 
					value={formData.confirmPassword}
					error={Boolean(validations.confirmPassword.trim())}
					name="confirmPassword"
					type="password"
					label="Confirm password"
					onChange={updateFormData}
					helperText={validations.confirmPassword}
				/>
				<Stack direction="row" spacing={2} ml="auto">
					<Button onClick={() => navigate("/login")} color="inherit">Cancel</Button>
					<SubmitButton text="Submit" />
				</Stack>
			</Paper>
		</Box>
	)
}