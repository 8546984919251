import { createTheme } from "@mui/material"; 
import { green } from "@mui/material/colors";
import { SideNav, toolbarHeight } from "./universal/divine-constants";

export  const theme = createTheme({
	palette: {
		primary: {
			main: green[500],
			light: green[50],
			dark: green[800]
		}
	},
	typography: {
		fontWeightBold: 600,
		fontFamily: [
			'"Nunito"',
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
	  	].join(','),
		body1: {
			fontSize: "0.875rem"
		},
		body2: {
			fontSize: "0.8rem"
		},
		button: {
			textTransform: "capitalize"
		}
	},
	components: {
		MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					color: "red",
					fontSize: "1rem"
				}
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: "outlined",
				color: "primary",
				size: "small",
				required: true,
				margin: "dense",
				helperText: " ",
				autoComplete: "off",
				minRows: 5,
				maxRows: 12,
				multiline: false,
				InputLabelProps: {
					shrink: true
				},
				FormHelperTextProps: {
					error: true
				}
			}
		},
		MuiTabs: {
			defaultProps: {
				variant: "scrollable",
				scrollButtons: true,
				allowScrollButtonsMobile: true,
				style: {
					marginBottom: "1rem"
				}
			}	
		},
		MuiTablePagination: {
			defaultProps: {
				component: "div",
				rowsPerPageOptions: [10, 20, 30, {label: "All", value: -1}],
				colSpan: 100
			}
		},
		MuiButton: {
			defaultProps: {
				size: 'medium',
				variant: "contained"
			},
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						pointerEvents: "auto"
					}
				}
			}
		},
		MuiCheckbox: {
			defaultProps: {
				size: "small",
				checked: false
			}
		},
		MuiDialog: {
			defaultProps: {
				keepMounted: false
			}
		},
		MuiTableCell: {
			defaultProps: {
				padding: "normal",
				size: "small"
			},
			styleOverrides: {
				root: {
					maxWidth: "250px",
					whiteSpace: "nowrap"
				}
			}
		},
		MuiTable: {
			defaultProps: {
				stickyHeader: true
			},
			styleOverrides: {
				root: {
					'&.nested-table > tbody > tr > td:has(div.MuiCollapse-root)' : {
						padding: 0,
						'td': {
							padding: 0
						},
						'div.MuiBox-root': {
							marginInline: 16,
							marginBottom: 16
						}
					},
				}
			}
		},
		MuiTableContainer: {
			defaultProps: {
				style: {
					maxHeight: '90vh'
				}
			}
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					'&.MuiTableBody-root > tr > td:has(+ tr > td table)': {
						borderBottom: "unset"
					}
				}
			}
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					"@media only screen and (min-width: 600px)": {
						minHeight: toolbarHeight,
						paddingLeft: 0
					},
					paddingLeft: 0,
				}
			}
		},
		MuiAppBar: {
			defaultProps: {
				style: {
					zIndex: SideNav.zIndex + 1,
					position: "sticky"
				}
			}
		},
		MuiMenu: {
			defaultProps: {
				anchorPosition: undefined,
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "right"
				},
				transformOrigin: {
					vertical: "top",
					horizontal: "right"
				}
			}
		},
		MuiListItemIcon: {
			defaultProps: {
				style: {
					scale: "0.8",
					minWidth: 40
				}
			}
		},
		MuiListItem: {
			defaultProps: {
				disablePadding: true
			}
		},
		MuiMenuItem: {
			defaultProps: {
				style: {
					paddingBlock: 4
				}
			}
		},
		MuiAlert: {
			defaultProps: {
				style: {
					width: "fit-content",
					marginInline: "auto",
					marginTop: 16
				},
				elevation: 5
			}
		},
		MuiDrawer: {
			defaultProps: {
				variant: "permanent",
				anchor: "left",
				style: {
				}
			},
			styleOverrides: {
				paper: {
					transition: "width 300ms ease",
					overflowX: "hidden",
					marginTop: toolbarHeight + "px",
					"&::-webkit-scrollbar": {
						width: 5,
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: green[800]
					},
					scrollbarWidth: "thin",
					borderRight: "none",
				  }
			}
		}
	},
})