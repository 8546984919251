import { Box, Checkbox, Chip, InputAdornment, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { emptyRows, handleSearch } from "../utils";
import { Search } from "@mui/icons-material";
import { api } from "../apiConfig";
import { Alert, User, UserTypes } from "../universal/divine-constants";
import ComponentTitle from "../universal/Title";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

interface MemberApi  {id: number, username: string, mobile_no: string, email: string, role: string ,status: boolean};

export default function SpaceMembersList() {
	const {user} = useContext(User);
	const {setAlert} = useContext(Alert);
	const [rows, setRows] = useState<MemberApi[]>([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const eptyRws = emptyRows(page, rowsPerPage, filteredList.length);

	async function toggleUserBlock(_: React.ChangeEvent<HTMLInputElement>, id: number) {
		setProgress(true);
		await api().patch(`api/delete_admin_user?status=${Number(!rows.find(e => e.id === id)?.status)}&user_id=${id}`).then(() => {
			fetchData();
		}).catch(() => setAlert({
			message: "Unable to toggle block status",
			severity: "error",
			show: true
		})).finally(() => setProgress(false));
	}	

	const fetchData = async () => await api().get("api/all_admin_user").then(res => {
		if(res.status === 200) {
			setRows(res.data);
		}
	}).catch(err => {
		setAlert({
			message: "Unable to fetch data",
			severity: "error",
			show: true
		});
	}).finally(() => setProgress(false));

	const tableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const next = handleSearch(rows, event.target.value);
		if(next.length <= 0) setPage(0);
		setFilteredList(next);
		if(event.target.value.trim().length === 0) setNoResults(false);
		else setNoResults(next.length <= 0);
	}

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);

	function handlePageChange(_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	}

	return (
		<>
		<ComponentTitle title="Members" />
		<Box>
			<Box className="table-actions">
				<TextField 
					type="text"
					label="Search"
					required={false}
					InputLabelProps={{shrink: undefined}}
					InputProps={{endAdornment: <InputAdornment position="end"><Search /></InputAdornment>}}
					placeholder="Eg. 5th July"
					onChange={tableSearch}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</Box>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>S.No</TableCell>
							<TableCell>Username</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Mobile No.</TableCell>
							<TableCell>Role</TableCell>
							<TableCell align="center">Blocked</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
							: filteredList
							).map((item, index) => <TableRow key={index}>
							<TableCell>{index + 1 + page * rowsPerPage}</TableCell>
							<TableCell>{item.username}</TableCell>
							<TableCell>{item.email}</TableCell>
							<TableCell>{item.mobile_no}</TableCell>
							<TableCell><Chip size="small" label={item.role} color={item.role === UserTypes.ADMIN ? "warning" : "secondary"} /></TableCell>
							<TableCell align="center">
								<Tooltip title={user?.type !== UserTypes.ADMIN ? "You don't have access to block" : ""}>
									<span>
										<Checkbox disabled={progress || user?.type !== UserTypes.ADMIN}  color="error" onChange={e => toggleUserBlock(e, item.id)} checked={!item.status} />
									</span>
								</Tooltip>
							</TableCell>
						</TableRow>)}
						{eptyRws > 0 && (
							<TableRow style={{ height: 53 * eptyRws }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
		</Box>
		</>
	)
}