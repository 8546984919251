import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./universal/Login";
import Users from "./admin/Users";
import Event from "./admin/Event";
import PictureUpload from "./admin/KavithaiPictures";
import CookingTips from "./admin/CookingTips";
import UserEventsList from "./admin/UserEventsList";
import EventDetails from "./admin/EventDetails";
import SpaceMembersList from "./admin/SpaceMembersList";
import { User, UserTypes } from "./universal/divine-constants";
import { useContext } from "react";
import {ForgotPassword, ResetPassword} from "./universal/ForgotPassword";

export default function Router() {
	const {user} = useContext(User);
	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
			{user?.type === UserTypes.ADMIN && <Route path="/users" element={<Users />} />}
			{user?.type === UserTypes.ADMIN && <Route path="/users/:userId" element={<UserEventsList />} />}
			{user?.type === UserTypes.ADMIN && <Route path="/event-details" element={<EventDetails />} />}
			{user?.type === UserTypes.ADMIN && <Route path="/events" element={<Event />} />}
			<Route path="/kavithai-pictures" element={<PictureUpload />} />
			<Route path="/cooking-tips" element={<CookingTips />} />
			{user?.type === UserTypes.ADMIN && <Route path="/space-members-list" element={<SpaceMembersList />} />}
			<Route path="/" element={<Navigate to={user?.loggedIn ? (user.type === UserTypes.ADMIN ? "/users" : "/kavithai-pictures") : "/login"} replace />} />
		</Routes>
	)
}