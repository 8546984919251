import { AlertColor } from "@mui/material";
import { createContext } from "react";

// // Types and Interfaces // //
export interface TUser {
	email: string | null;
	loggedIn: boolean;
	type: UserTypes | string | null | undefined
}

export interface AlertMessage {
	title?: string;
    message: string;
    severity: AlertColor;
    show: boolean;
}

export type State<T> = React.Dispatch<React.SetStateAction<T>>;
export interface DrawerLink {path: string, icon: JSX.Element, name: string, access: Array<UserTypes.ADMIN | UserTypes.MOD>};

// // Global contexts // //
export const User = createContext<{
	user: TUser | undefined;
	setUser: React.Dispatch<React.SetStateAction<TUser>>
}>({
	user: undefined,
	setUser: () => null
});

export const Alert = createContext<{
	alert: AlertMessage | undefined;
	setAlert: State<AlertMessage>
}>({
	alert: {title: "", message: "", severity: "success", show: false},
	setAlert: () => null
});

export const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

// // Divine constants // //
export enum SideNav {
	Expanded = 225,
	Collapsed = 56,
	zIndex = 1200
}

export enum UserTypes {
	ADMIN = "admin",
	MOD = "moderator"
}

export const toolbarHeight = 56;

export const imageTypes: string[] = [
	"image/apng",
	"image/avif",
	"image/gif",
	"image/jpeg",
	"image/png",
	"image/svg+xml",
	"image/webp",
];
  