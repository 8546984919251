import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Stack, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, useMediaQuery, LinearProgress, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { api } from "../apiConfig";
import { useContext, useEffect, useState } from "react";
import { Alert, User, UserTypes } from "../universal/divine-constants";
import ComponentTitle from "../universal/Title";

interface Details {
	id: number,
	event_id: number,
	event_name: string,
	title: string,
	content: string,
	book_image: string,
	book_type: string,
	languge: string,
	book_category: string,
	status: string | null,
	overview: string | null,
	created_on: string,
	author: string,
	author_id: number
}

export default function EventDetails() {
	const {user} = useContext(User);
	const {setAlert} = useContext(Alert);
	const [params] = useSearchParams();
	const [data, setData] = useState<Details[]>();
	const [content, setContent] = useState({author: "", text: ""});
	const [storyDialog, setStoryDialog] = useState(false);
	const [winnerDialog, setWinnerDialog] = useState(false);
	const [activeAuthor, setActiveAuthor] = useState({author: "", authorId: -1});
	const [progress, setProgress] = useState(false);
	const [storyProgress, setStoryProgress] = useState(false);

	const fetchData = async () => await api().get(`api/get_event_user_data?book_type=${params.get("book_type")}&event_id=${params.get("event_id")}`).then(res => {
		setData(res.data);
	}).catch(err => {
		setAlert({
			message: "Unable to fetch data",
			severity: "error",
			show: true
		});
	});
	const mobile = useMediaQuery("(max-width: 750px)");
	const tablet = useMediaQuery("(max-width: 994px)");

	const fetchStory = async (id: number, book_type: string, author: string) => {
		await api().get(`api/book/event_book?book_id=${id}&book_type=${book_type}`).then(res => {
			setContent({author: author + '\'s', text: res.data});
		}).catch(() => {
			setAlert({
				message: "Unable to fetch story",
				severity: "error",
				show: true
			});
		}).finally(() => setStoryProgress(false))
	}

	const submitWinnerPost = (id: number) => {
		setProgress(true);
		api().post("api/event_winner_list", {
			event_id: params.get("event_id"),
			winners_id: id
		}).then(res => {
			if(res.data.message === "event_update_successfully") {
				setAlert({
					message: "Successfully set " + data?.find(d => d.author_id === id)?.author + " as winner",
					severity: "success",
					show: true
				});
			}
		}).catch(() => {
			setAlert({
				message: "Unable to set " + data?.find(d => d.author_id === id)?.author + " as winner",
				severity: "error",
				show: true
			});
		}).finally(() => {
			setProgress(false);
			setWinnerDialog(false);
		})
	}

	useEffect(() => {
		fetchData();
	}, [])
	
	
	return (
		<Box>
			<Dialog open={storyDialog} fullWidth>
				<DialogTitle>Story</DialogTitle>
				{storyProgress && <LinearProgress />}
				<DialogContent>
					<DialogContentText>
						{content.text}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="text" onClick={() => {setStoryDialog(false); setContent({author: "", text: ""})}}>Close</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={winnerDialog}>
				<DialogTitle>Winner</DialogTitle>
				{progress && <LinearProgress variant="indeterminate" />}
				<DialogContent>
					This action cannot be undone ! Are you sure you want to set <strong>{activeAuthor.author}</strong> as winner of <strong>{data?.find(i => i.author_id === activeAuthor.authorId)?.event_name}</strong> ?
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="warning" onClick={() => {setWinnerDialog(false)}}>Close</Button>
					<Button color="primary" variant="contained" onClick={() => submitWinnerPost(activeAuthor.authorId)}>Set winner</Button>
				</DialogActions>
			</Dialog>
			<ComponentTitle title="Event participants" />
			<Box display="grid" gridTemplateColumns={mobile ? "1fr" : tablet ? "1fr 1fr" : "1fr 1fr 1fr"} gap="0.5rem">
				{data?.map((item, index) => (
					<Card key={index}>
						<CardHeader 
							title={item.title}
							subheader={"By " + item.author}
						/>	
						<CardMedia component="img" alt="front cover" image={item?.book_image} title="front cover" />

						<CardContent>						
							<Stack direction="row" gap="1rem">
								<Chip size="small" label={item?.book_type ?? "null"} variant="outlined" color="info" />
								<Chip size="small" label={item?.status ?? "inactive"} variant="outlined" color="info" /><br/>
							</Stack>
						</CardContent>

						<CardActions>
							<Button sx={{mr: 1}} variant="contained" color="secondary" size="small" onClick={() => {setStoryProgress(true); fetchStory(item.id, item.book_type, item.author); setStoryDialog(true)}}>story</Button>
							<Tooltip title={user?.type !== UserTypes.ADMIN ? "Contact your administrator to set the winner" : ""}>
								<span>
									<Button disabled={user?.type !== UserTypes.ADMIN} color="warning" size="small" variant="outlined" onClick={() => {setActiveAuthor({author: item.author, authorId: item.author_id}); setWinnerDialog(true)}}>Set Winner</Button>
								</span>
							</Tooltip>
						</CardActions>
					</Card>
				))}
			</Box>
		</Box>
	)
}