import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState, useContext } from "react";
import { api } from "../apiConfig";
import { Alert } from "../universal/divine-constants";
import { State } from "../universal/divine-constants";

export default function DeleteModal(props: {url: string, setDialogDisplay: State<boolean>, dialogDisplay: boolean, fetchData: () => void, data?: any, method?: "POST" | "PATCH"}) {
	const [progress, setProgress] = useState(false);
	const {setAlert} = useContext(Alert);

	function handleDelete() {
		setProgress(true);	
		(props.method === "POST" ? api().post(props.url, props.data) : api().patch(props.url, props.data)).then(res => {
			if(res.status === 200) {
				props.setDialogDisplay(false);
				setAlert({
					title: "",
					message: "Record deleted successfully",
					severity: "success",
					show: true	
			});
				props.fetchData();
			}
		}).catch(() => {
			setAlert({
				title: "",
				message: "Unable to delete",
				severity: "error",
				show: true	
			});
		}).finally(() => setProgress(false));
	}
	return <Dialog open={props.dialogDisplay} disableEnforceFocus keepMounted>
		<DialogTitle color="red">Warning !</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Are you sure you want to delete this record ?<br />
				<br />
				<b>This action is not reversible.</b>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button variant="text" disabled={progress} onClick={handleDelete} color="error"> {progress && <><CircularProgress sx={{float: "right"}} size="16px" />&nbsp;&nbsp;</>} Delete</Button>
			<Button variant="text" disabled={progress} onClick={() => props.setDialogDisplay(false)} color="warning">Cancel</Button>
		</DialogActions>
	</Dialog>
}
