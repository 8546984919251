import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, LinearProgress, Skeleton, Stack, Typography, useMediaQuery } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { api } from "../apiConfig";
import { ArrowBackIosNew, EmailOutlined, PhoneOutlined, Star, ThumbUpAltOutlined } from "@mui/icons-material";
import ComponentTitle from "../universal/Title";
import { Alert } from "../universal/divine-constants";

interface User {
	readonly id: number;
	readonly username: string;
	readonly mobile_no: string;
	readonly email: string;
	readonly premium: boolean;
	readonly language: string;
	readonly bio: string;
	readonly following: number;
	readonly follower: number;
}

interface UserBook {
	readonly title: string;
	readonly book_type: string;
	readonly languge: string;
	readonly content: string;
	readonly book_category: string;
	readonly overview: string;
	readonly author: string;
	readonly image: string;
	readonly id: number;
	readonly author_id: number;
	readonly like_count: number;
	readonly views_count: number;
	readonly like_status: number;
}

export default function UserEventsList() {
	const {userId} = useParams();
	const {setAlert} = useContext(Alert);
	const [userAccount, setUserAccount] = useState<User>();
	const [bookDataList, setBookDataList] = useState<UserBook[]>();
	const [bookContent, setBookContent] = useState<string>();
	const [storyDialog, setStoryDialog] = useState(false);
	const [loading, setLoading] = useState(true);
	const [storyProgress, setStoryProgress] = useState(false);
	const sm = useMediaQuery("(max-width: 750px)");
	const md = useMediaQuery("(max-width: 994px)");
	const lg = useMediaQuery("(max-width: 1536px)");

	const fetchData = () => api().get("api/get_user_account?user_id=" + userId).then(res => {
		setUserAccount(res.data as User);
		setLoading(false);
	});

	const fetchBookData = async () => await api().get(`api/book_by_user?user_id=${userId}&book_type=all`).then(res => {
		setBookDataList(res.data);
	}).catch(err => {
		setAlert({
			message: "Unable to fetch data",
			severity: "error",
			show: true
		});
	});

	const getBookData = async (id: number,type:string) => await api().get(`api/book/one_book?book_id=${id}&book_type=${type}`).then(res => {
		setBookContent(res.data as string);
	}).catch(() => setBookContent("<span style=\"color: red\">Unable to fetch book data<\\span>")).finally(() => setStoryProgress(false));

	useEffect(() => {
		fetchData();
		fetchBookData();
	}, []);


	return loading ? 
		(<Box>
			<Typography variant="h3"><Skeleton width="50%" /></Typography>
			<Skeleton width="30%" variant="text" sx={{display: "inline-block"}} />
			&nbsp;&nbsp;
			<Skeleton  width="30%" variant="text" sx={{display: "inline-block"}} />
			&nbsp;&nbsp;
			<Skeleton  width="30%" variant="text" sx={{display: "inline-block"}} />
			<br />
			<br />
			{Array.from({length: 10}).fill(0).map((_, ind) => (
				<Skeleton variant="rectangular" key={ind} width="100%" height="60px" sx={{mb: 2}} />
			))}
		</Box>) : 
		<>
		<Box className="user-account-details">
			<Box>
				<Link to="/users">
					<IconButton>
						<ArrowBackIosNew sx={{display: "inline-block"}} />
					</IconButton>
				</Link>
				&nbsp;&nbsp;
				<ComponentTitle title={userAccount?.username as string} />
				{userAccount?.premium && <Chip size="small" sx={{ml: 1}} icon={<Star />} label="premium" color="warning" />}
			</Box>
			<Box sx={{display: sm ? "block" : "flex", gap: sm ? "" : "1rem"}}>
				<Stack direction="column" spacing={1}>
					<Chip size="small" sx={{width: "fit-content"}} icon={<PhoneOutlined />} label={userAccount?.mobile_no} />
					<Chip size="small" sx={{width: "fit-content"}} icon={<EmailOutlined />} label={userAccount?.email} />
				</Stack>
				{sm ? <Divider sx={{marginBlock: 1}} /> : <Divider variant="middle" orientation="vertical" flexItem />}
				<Stack direction="row" spacing={2} sx={{
					width: "100%",
					justifyContent: "left",
					alignItems: "center",
					"@media (max-width: 750px)": {
						justifyContent: "space-evenly",
					}
				}}>
					<Box>
						<Typography align="center" variant="subtitle2">{userAccount?.follower}</Typography>&nbsp;
						<Typography variant="caption">followers</Typography>
					</Box>
					<Box>
						<Typography align="center" variant="subtitle2">{userAccount?.following}</Typography>&nbsp;
						<Typography variant="caption">following</Typography>
					</Box>
				</Stack>
				{sm && <Divider sx={{mb: 1}} />}
			</Box>
		</Box>
		<br />
		<Box display="grid" gridTemplateColumns={sm ? "1fr" : md ? "1fr 1fr" : lg ? "1fr 1fr 1fr" : "repeat(4, 1fr)"} gap="0.5rem">
			{bookDataList?.map(book => 
			<Card key={book.id} sx={{width: "100%"}}>
				<CardHeader title={book.title} titleTypographyProps={{variant: "h6"}} subheader={<Chip size="small" icon={<ThumbUpAltOutlined />} label={book.like_count} />} />
				<CardMedia component="img" image={book.image} alt={book.image} />
				<CardContent>
				<Typography variant="body1">
					{book.overview}
				</Typography>
				</CardContent>
				<CardActions>
				<Button variant="text" size="small" onClick={() => {setStoryProgress(true); getBookData(book.id,book.book_type); setStoryDialog(true)}}>View story</Button>
				</CardActions>
			</Card>)}
		</Box>
		<Dialog open={storyDialog} fullWidth maxWidth="md">
			<DialogTitle>Story</DialogTitle>
			{storyProgress && <LinearProgress />}
			<DialogContent>
				<DialogContentText dangerouslySetInnerHTML={{__html: bookContent as string}} />
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="warning" onClick={() => setStoryDialog(false)}>Close</Button>
			</DialogActions>
		</Dialog>
		</>
		
}