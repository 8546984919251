import { Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, InputAdornment, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useState, useEffect, useContext, useCallback } from "react";
import { emptyRows, handleSearch } from "../utils";
import { ArrowRightAltOutlined, Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { api } from "../apiConfig";
import { TablePaginationActions } from "../components/Table-Pagination";
import ComponentTitle from "../universal/Title";
import { Alert, User, UserTypes } from "../universal/divine-constants";

interface UserApi  {id: number, username: string, mobile_no: string, email: string, report_count:string ,report_data: ReportData[],status: boolean};
interface CountDetails {user_count: number, Book_count: number, Poem_count: number, novel_count: number, essay_count: number}
interface ReportData {
	report: string;
	repoter_name: string;
  }
export default function Users() {
	const {user} = useContext(User);
	const {setAlert} = useContext(Alert);
	const [rows, setRows] = useState<UserApi[]>([]);
	const [filteredList, setFilteredList] = useState(rows);
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [countData, setCountData] = useState<CountDetails>({
		Book_count: 0, essay_count: 0, novel_count: 0, Poem_count: 0, user_count: 0
	});
	const eptyRws = emptyRows(page, rowsPerPage, filteredList.length);
    const [reportDialogOpen, setReportDialogOpen] = useState(false);
    const [reportData, setReportData] = useState<ReportData[]>([]);
	async function toggleUserBlock(_: React.ChangeEvent<HTMLInputElement>, id: number) {
		setProgress(true);
		await api().patch(`api/delete_appuser_user?status=${Number(!rows.find(e => e.id === id)?.status)}&user_id=${id}`).then(() => {
			fetchData();
		}).catch(() => setAlert({
			message: "Unable to toggle block status",
			severity: "error",
			show: true
		})).finally(() => setProgress(false));
	}	

	const fetchData = useCallback(() => Promise.all([api().get("api/user/user_list"), api().get("api/user_count_list")]).then(([userlist, userCount]) => {
		if(userlist.status === 200) {
			setRows(userlist.data);
		}
		if(userCount.status === 200) {
			setCountData(userCount.data as CountDetails);
		}
	}).catch(err => {
		setAlert({
			message: "Unable to fetch data",
			severity: "error",
			show: true,
			title: ""
		})
	}).finally(() => setProgress(false)), []);

	const handleViewReports = (reportData: ReportData[]) => {
		setReportData(reportData);
		setReportDialogOpen(true);
	  };

	const handleCloseReportDialog = () => {
		setReportDialogOpen(false);
	  };
	

	const tableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const next = handleSearch(rows, event.target.value);
		if(next.length <= 0) setPage(0);
		setFilteredList(next);
		if(event.target.value.trim().length === 0) setNoResults(false);
		else setNoResults(next.length <= 0);
	}

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);

	function handlePageChange(_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	}
	

	return (
		<>
		<ComponentTitle title="Users" />
		<Stack direction="row" spacing={2} useFlexGap flexWrap="wrap">
			<Card>	
				<CardContent>
					<Typography color="text.secondary">Total Users</Typography>
					<Typography variant="h4">{countData.user_count}</Typography>
				</CardContent>
			</Card>
			<Card>	
				<CardContent>
					<Typography color="text.secondary">Total Books</Typography>
					<Typography variant="h4">{countData.Book_count}</Typography>
				</CardContent>
			</Card>
			<Card>	
				<CardContent>
					<Typography color="text.secondary">Total Novels</Typography>
					<Typography variant="h4">{countData.novel_count}</Typography>
				</CardContent>
			</Card>
			<Card>	
				<CardContent>
					<Typography color="text.secondary">Total Peoms</Typography>
					<Typography variant="h4">{countData.Poem_count}</Typography>
				</CardContent>
			</Card>
			<Card>	
				<CardContent>
					<Typography color="text.secondary">Total Essays</Typography>
					<Typography variant="h4">{countData.essay_count}</Typography>
				</CardContent>
			</Card>
		</Stack>
		<br />
		<Box>
			<Box className="table-actions">
				<TextField 
					type="text"
					label="Search"
					required={false}
					InputLabelProps={{shrink: undefined}}
					InputProps={{endAdornment: <InputAdornment position="end"><Search /></InputAdornment>}}
					placeholder="Eg. 5th July"
					onChange={tableSearch}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
			</Box>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>S.No</TableCell>
							<TableCell>Username</TableCell>
							<TableCell>Email</TableCell>
							<TableCell>Mobile No.</TableCell>
							<TableCell>Report count</TableCell>
							<TableCell>Report</TableCell>
							<TableCell align="center">View Events</TableCell>
							<TableCell align="center">Blocked</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
							: filteredList
							).map((item, index) => <TableRow key={index}>
							<TableCell>{index + 1 + page * rowsPerPage}</TableCell>
							<TableCell>{item.username}</TableCell>
							<TableCell>{item.email}</TableCell>
							<TableCell>{item.mobile_no}</TableCell>
							<TableCell>{item.report_count}</TableCell>
							<TableCell align="center">
								
									<Button variant="contained" color="primary" onClick={() => handleViewReports(item.report_data)}>View Reports</Button>
								
							</TableCell>
							<TableCell align="center">
								<Link to={"/users/" + item.id}>
									<IconButton color="primary">
										<ArrowRightAltOutlined />
									</IconButton>
								</Link>
							</TableCell>
							
							<TableCell align="center">
								<Tooltip title={user?.type !== UserTypes.ADMIN ? "You don't have access to delete" : ""}>
									<span>
										<Checkbox disabled={progress || user?.type !== UserTypes.ADMIN} onChange={(e) => toggleUserBlock(e, item.id)} color="error" checked={!item.status} />
									</span>
								</Tooltip>
							</TableCell>
						</TableRow>)}
						{eptyRws > 0 && (
							<TableRow style={{ height: 53 * eptyRws }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
		</Box>
		<Dialog open={reportDialogOpen} onClose={handleCloseReportDialog}>
        <DialogTitle>User Reports</DialogTitle>
        <DialogContent>
          {reportData.length > 0 ? (
            reportData.map((report, index) => (
              <Box key={index} mb={2}>
                <DialogContentText><b>Report:</b> {report.report}</DialogContentText>
                <DialogContentText><b>Reporter Name:</b> {report.repoter_name}</DialogContentText>
              </Box>
            ))
          ) : (
            <DialogContentText>No reports available.</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReportDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}