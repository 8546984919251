import { Box, FormControl, FormControlLabel, InputAdornment, Paper, TextField, Typography, Checkbox } from "@mui/material";
import { useState, type ChangeEvent, FormEvent, useContext, useEffect } from "react";
import { green } from "@mui/material/colors";
import { KeyOutlined, PersonOutlined } from "@mui/icons-material";
import SubmitButton from "../components/SubmitButton";
import { useNavigate } from "react-router-dom";
import { Alert, User, UserTypes } from "./divine-constants";
import { api } from "../apiConfig";
import { Link } from "react-router-dom";

export default function Login() {
	// eslint-disable-next-line no-unused-vars
	const {user, setUser} = useContext(User);
	const {setAlert} = useContext(Alert);
	const navigate = useNavigate();
	const [formData, setFormdata] = useState({
		email: "",
		password: "",
		remember: false
	});
	const [validations, setValidations] = useState({
		email: " ",
		password: " ",
	});
	useEffect(() => {
		sessionStorage.clear();
		if(user?.loggedIn) {
			setAlert({
				message: "You are already logged in !",
				show: true,
				severity: "warning"
			})
			return navigate('/users');
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	function updateFormData(event: ChangeEvent<HTMLInputElement>) {
		setFormdata(current => ({...current, [event.target.name]: event.target.value}))
		if(Object.values(validations).some(it => it !== " ")) {
			setValidations(curr => ({...curr, [event.target.name]: " "}));
		}
	}

	function validation() {
		let isValid = true;
		if (formData.email.trim() === "") {
			isValid = false;
			setValidations(curr => ({...curr, email: "Email is required."}))
		} else if (!formData.email.trim().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)){
			isValid = false;
			setValidations(curr => ({...curr, email: "Invalid Email address"}))
		} else
			setValidations(curr => ({...curr, email: " "}));

		if (formData.password.trim() === "") {
			isValid = false;
			setValidations(curr => ({...curr, password: "Password is required."}))
		} else setValidations(curr => ({...curr, password: " "}))
		return isValid;
	}

	async function onSubmit(event: FormEvent<HTMLFormElement>) {
		event.preventDefault();
		if(!validation()) {
			return;
		};
		api().post("api/login_user", {
			password: formData.password,
			email: formData.email
		}).then(res => {
			if(res.data.message === "login_successfully") {
				const userType = res.data["role"] as UserTypes;
				setUser({
					email: formData.email,
					loggedIn: true,
					type: userType
				});
				if(userType === UserTypes.ADMIN){
					navigate("/users");					
				}
				else if(userType === UserTypes.MOD) {
					navigate("/kavithai-pictures");
				}
				else {
					setAlert({
						message: "User type does not exist",
						severity: "error",
						show: true
					});
				}
				localStorage.setItem("token", res.data["token"]);
				localStorage.setItem("email", formData.email);
				localStorage.setItem("type", res.data["role"]);
			}
		}).catch(error => {
			console.log(error);
  			console.log(error.response);
			if (error.response === 'your account block please contant admin'){
				setAlert({
					message: "your Account Blocked.",
					show: true,
					severity: "error"
				});
			}
			else{
				setAlert({
					message: "Invalid credentials.",
					show: true,
					severity: "error"
				});
			}
		});
	}

 	return (
		<Box component="div" style={{
			width: "100vw",
			height: "100vh",
			position: "fixed",
			top: 0,
			display: "grid",
			overflow: "auto",
			placeItems: "center",
			zIndex: 1201,
			backgroundColor: green["50"]
		}}>
			<Paper style={{
				width: "40%",
				minWidth: "275px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				gap: 0,
				marginInline: "auto",
				padding: "1rem 2rem 1.5rem",
			}} component="form" elevation={2} onSubmit={onSubmit} noValidate>
				<img 
					src="/logo192.png" 
					alt="logo" 
					style={{
						width: "50%",
						marginInline: "auto",
						minWidth: 150,
						display: "block"
					}} 
				/>
				<Typography variant="subtitle1" color={green["800"]} style={{opacity: "0.5"}}>Login to continue.</Typography>
				<TextField 
					value={formData.email}
					error={Boolean(validations.email.trim())}
					name="email"
					type="text"
					placeholder="example@service.com"
					label="Email"
					onChange={updateFormData}
					helperText={validations.email}
					InputLabelProps={{shrink: undefined}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<PersonOutlined />
							</InputAdornment>
						)
					}}
				/>
				<TextField
					value={formData.password}
					error={Boolean(validations.password.trim())}
					name="password"
					type="password"
					placeholder="Password"
					label="Password"
					onChange={updateFormData}
					helperText={validations.password}
					InputLabelProps={{shrink: undefined}}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<KeyOutlined />
							</InputAdornment>
						)
					}}
				/>
				<Box component="div" style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					flexWrap: "wrap",
					marginBottom: "1rem"
				}}>
					<FormControl>
						<FormControlLabel name="remember" control={<Checkbox checked={formData.remember} onChange={e => setFormdata(current => ({...current, remember: e.target.checked}))}/>} label="Remember me" />
					</FormControl>

					<Link to="/forgot-password" style={{cursor: "pointer"}}>Forgot password ? </Link>
				</Box>
				<SubmitButton text="Submit" />
			</Paper>
		</Box>
	)
}