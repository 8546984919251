import { DeleteOutlined, EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export function Redbin(props: {onClick?: React.MouseEventHandler<HTMLButtonElement>, disabled?: boolean}) {
	return <Tooltip title="Delete"><IconButton color="error" disabled={props.disabled} onClick={props.onClick}><DeleteOutlined /></IconButton></Tooltip>
}

export function EditItem(props: {onClick?: React.MouseEventHandler<HTMLButtonElement>, disabled?: boolean}) {
	return <Tooltip title="Edit"><IconButton color="primary" disabled={props.disabled} onClick={props.onClick}><EditOutlined /></IconButton></Tooltip>
}

export function ViewItem(props: {onClick?: React.MouseEventHandler<HTMLButtonElement>, disabled?: boolean}) {
	return <Tooltip title="View"><IconButton color="warning" disabled={props.disabled} onClick={props.onClick}><RemoveRedEyeOutlined /></IconButton></Tooltip>
}
