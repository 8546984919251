import { useContext } from "react"
import { Alert as AlertToast } from "../universal/divine-constants"
import { Alert, AlertTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function AlertMessage() {
	const {alert, setAlert} = useContext(AlertToast);
	return (
		<Alert style={{
			position: "absolute", 
			marginInline: "auto", 
			zIndex: 2000,
			top: "1.5rem",
			left: "50%",
			transform: "translate(-50%, 0)"
		}} action={
				<IconButton size="small" onClick={() => setAlert({show: false, message: "", severity: "success"})}><Close /></IconButton>
			} severity={alert?.severity}>
			<AlertTitle>{alert?.title}</AlertTitle>
			{alert?.message}
		</Alert>
	)
}