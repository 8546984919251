import { Box, Button, Card, CardContent, CardHeader, CardMedia, IconButton, LinearProgress, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { api } from "../apiConfig";
import ComponentTitle from "../universal/Title";
import { EditOutlined, DeleteOutlined } from "@mui/icons-material";
import UpdateCookingTip from "./UpdateCookingTip";
import DeleteModal from "./DeleteModal";
import { Alert } from "../universal/divine-constants";

export interface FormData {
	id: number, 
	title: string, 
	description: string, 
	image: string | ArrayBuffer | null | undefined, 
	file?: File | null
}

export default function CookingTips() {
	const {setAlert} = useContext(Alert);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [deleteId, setDeleteId] = useState<number>(-1);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [tips, setTips] = useState<{id: number, image: string, content: string, name: string, status: boolean, created_at: Date}[]>();
	const [loadingImages, setLoadingImages] = useState(false);
	const [formData, setFormData] = useState<FormData>({
		id: -1,
		title: "", 
		description: "",
		image: null
	});
	const fetchData = () => {
		setLoadingImages(true);
		api().get("api/get_cooking_details").then(res => {
			setTips(res.data);
		}).catch(err => {
			setAlert({
				message: "Unable to fetch data",
				severity: "error",
				show: true
			})
		}).finally(() => {
			setLoadingImages(false);
		});
	}
	const addNewFormData = () => setFormData({
		id: -1,
		title: "", 
		description: "",
		image: null
	});
	const editFormData = (item: {id: number, image: string, content: string, name: string, status: boolean, created_at: Date}) => setFormData({
		id: item.id,
		title: item.name,
		description: item.content,
		image: item.image
	});
	const sm = useMediaQuery("(max-width: 750px)");
	const md = useMediaQuery("(max-width: 994px)");
	const lg = useMediaQuery("(max-width: 1536px)");

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
		<ComponentTitle title="Cooking tips" />
		<Box className="table-actions">
			<Button variant="outlined" onClick={() => {addNewFormData(); setOpenDialog(true)}}>Add Cooking tip</Button>
		</Box>
		<div style={{width: "100%", marginTop: 8}}>
				{loadingImages && <LinearProgress variant="indeterminate" />}
			</div>
		{!tips?.length && !loadingImages && "There are no cooking tips to show"}
		<br />
		<Box display="grid" gridTemplateColumns={sm ? "1fr" : md ? "1fr 1fr" : lg ? "1fr 1fr 1fr" : "repeat(4, 1fr)"} gap="0.5rem" className="cooking-tips-container">		
			{tips?.map(it => 
			it.status && <Card key={it.id} sx={{width: "100%"}}>
				<CardHeader 
					disableTypography
					action={<>
						<IconButton size="small" aria-label="settings" onClick={() => {editFormData(it); setOpenDialog(true)}}>
							<EditOutlined />
						</IconButton>
						<IconButton size="small" aria-label="settings" onClick={() => {setDeleteId(it.id); setDeleteDialog(true)}}>
							<DeleteOutlined />
						</IconButton>
						</>
					}
					title={<Typography variant="h6">{it.name}</Typography>} 
				/>
				<CardMedia
					component="img"
					height="194"
					image={it.image}
					alt={it.image}
				/>
				<CardContent>
					<Typography variant="body1">
						{it.content}
					</Typography>
				</CardContent>
			</Card>
			)}	
		</Box>
		<UpdateCookingTip openDialog={openDialog} setOpenDialog={setOpenDialog} formData={formData} setFormData={setFormData} fetchData={fetchData} />
		<DeleteModal dialogDisplay={deleteDialog} setDialogDisplay={setDeleteDialog} url={"api/delete_cooking_tips"} data={{cooking_id: deleteId}} fetchData={fetchData} method="POST" />
		</>
	)
}