import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem, TextField } from "@mui/material";
import { Alert, State } from "../universal/divine-constants";
import { useContext, useState } from "react";
import { handleTextChange } from "../utils";
import { api } from "../apiConfig";

interface FormData {
	username: string;
	mobileNo: string;
	password: string;
	email: string;
	role?: string
}

export default function AddAdminUser(props: {openDialog: boolean, setOpenDialog: State<boolean>}) {
	const [formData, setFormData] = useState<FormData>({
		username: "",
		mobileNo: "",
		password: "",
		email: "",
		role: ""
	});
	const [progress, setProgess] = useState<boolean>(false);
	const [error, setError] = useState<FormData>({
		username: " ",
		mobileNo: " ",
		password: " ",
		email: " "
	});
	const {setAlert} = useContext(Alert);

	function validate(): boolean {
		let isValid = true;
		if(formData.username.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, username: "Please enter a name"}));
		} else setError(curr => ({...curr, username: " "}));
		if(formData.mobileNo.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, mobileNo: "Please enter a mobile number"}));
		} else if(formData.mobileNo.trim().match(/^([0|\\+[0-9]{1,5})?([7-9][0-9]{9})$/)) {
			isValid = false;
			setError(curr => ({...curr, mobileNo: "Invalid phone number"}));
		} else setError(curr => ({...curr, mobileNo: " "}));
		if(formData.email.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, email: "Please enter an email"}));
		} else setError(curr => ({...curr, email: " "}));
		if(formData.password.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, password: "Please enter an end date"}));
		} else setError(curr => ({...curr, password: " "}));
		if(formData.role === "") {
			isValid = false;
			setError(curr => ({...curr, role: "Please select a role"}));
		} else setError(curr => ({...curr, role: " "}));
		return isValid
	}

	function removeError(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setError(curr => ({...curr, [e.target.name]: " "}));
	}
	function handleFormSubmit() {
		setProgess(true);
		if(!validate()) {
			setProgess(false);
			return;
		};
		api().post("api/add_admin_user", {
			user_name: formData.username,
			password: formData.password,
			mobile_no: formData.mobileNo,
			email: formData.email,
			role: formData.role
		}).then(res => {
			if(res.data.message === "resister_successfully") {
				setAlert({
					show: true,
					message: "User added successfully",
					severity: "success"
				});
			}
		}).catch(() => {
			setAlert({
				show: true,
				message: "Unable to add user. Please try again later.",
				severity: "error"
			});
		}).finally(() => {
			setProgess(false);
			props.setOpenDialog(false);
		})
	}

	return (
		<Dialog keepMounted={true} open={props.openDialog} onClose={() => props.setOpenDialog(false)}>
			<DialogTitle>Add new user</DialogTitle>
			{progress && <LinearProgress />}
			<DialogContent>
				<form onSubmit={handleFormSubmit}>
					<TextField
						type="text"
						name="username"
						label="Username"
						placeholder="username"
						helperText={error.username}
						onChange={e => {handleTextChange(e, setFormData); removeError(e)}}
						value={formData.username} 
					/>
					<TextField
						type="text"
						name="email"
						label="Email"
						placeholder="example@service.com"
						helperText={error.email}
						onChange={e => {handleTextChange(e, setFormData); removeError(e)}}
						value={formData.email} 
					/>
					<TextField
						type="password"
						name="password"
						label="Password"
						helperText={error.password}
						onChange={e => {handleTextChange(e, setFormData); removeError(e)}}
						value={formData.password} 
					/>
					<TextField
						type="tel"
						name="mobileNo"
						label="Mobile No."
						placeholder="+91 1234567890"
						helperText={error.mobileNo}
						onChange={e => {handleTextChange(e, setFormData); removeError(e)}}
						value={formData.mobileNo} 
					/>
					<TextField
						select
						name="role"
						label="Role"
						helperText={error.role}
						onChange={e => {handleTextChange(e, setFormData); removeError(e)}}
						value={formData.role} 
					>
						<MenuItem value="admin">Admin</MenuItem>
						<MenuItem value="moderator">Moderator</MenuItem>
					</TextField>
				</form>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="warning" onClick={() => props.setOpenDialog(false)}>Cancel</Button>
				<Button variant="text" onClick={handleFormSubmit}>Submit</Button>
			</DialogActions>
		</Dialog>
	)
}