import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, LinearProgress, TextField, useMediaQuery } from "@mui/material"
import { useState, useEffect, useContext } from "react"
import { api } from "../apiConfig";
import { Alert, imageTypes } from "../universal/divine-constants";
import { theme } from "../theme";
import ComponentTitle from "../universal/Title";
import { DeleteOutline } from "@mui/icons-material";
import DeleteModal from "./DeleteModal";

export default function PictureUpload() {
	const {setAlert} = useContext(Alert);
	const [images, setImages] = useState<{image: string, id: number, status: boolean}[]>([]);
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleteImageId, setDeleteImageId] = useState<number>();
	const clickOpenDialog = () => setOpenDialog(true);
	const clickCloseDialog = () => setOpenDialog(false);
	const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
	const matchUpMd = useMediaQuery(theme.breakpoints.down("md"));
	const [imageList, setImageList] = useState<FileList>({} as FileList);
	const [error, setError] = useState<string>(" ");
	const [progress, setProgress] = useState<boolean>(false);
	const [loadingImages, setLoadingImages] = useState(false);

	const fetchData = () => {
		setLoadingImages(true); 
		api().get("api/get_kavithai_image_list").then(res => {
			if(res.status === 200) {
				setImages(res.data);
			}
		}).catch(err => {
			setAlert({
				message: "Unable to fetch data",
				severity: "error",
				show: true
			});
		}).finally(() => {
			setLoadingImages(false);
		})
	};

	function submitImage() {
		let isValid = true;
		setProgress(true);
		if(imageList.length === 0 || imageList.length === undefined) {
			setError("Please upload an image");
			isValid = false;
		} else if (!Array.from(imageList).every(file => imageTypes.includes(file.type))) {
			setError(imageList.length > 1 ? "Some of your files are not valid image files." : imageList[0].type + " is not a valid image file.");
			isValid = false;
		} else {
			setError(" ");
		};
		if(!isValid) {
			setProgress(false);
			return;
		};
		api().post("api/add_kavithai_image", {
			image: Array.from(imageList)
		}).then(() => {
			setAlert({message: "Images added successfully", show: true, severity: "success"});
			fetchData();
		}).catch(() => 
			setAlert({message: "Unable to add images. Please try again later", show: true, severity: "success"})
		).finally(clickCloseDialog);
	}

	useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
		<ComponentTitle title="Kavithai Pictures" />
		<Box className="table-actions">
			<Button variant="outlined" onClick={clickOpenDialog}>Add Image</Button>
			<div style={{width: "100%", marginTop: 8}}>
				{loadingImages && <LinearProgress variant="indeterminate" />}
			</div>
			{!images.length && !loadingImages && "There are no Kavithai Pictures to show"}
			<ImageList sx={{mt: 4}} variant="masonry" cols={matchDownSm ? 2 : matchUpMd ? 3 : 4} gap={8}>
			{images.map((image, ind) => 
				image.status && <ImageListItem key={ind}>
					<img
						src={image.image}
						alt={"kavithai img " + image.id}
						loading="lazy"
					/>
					<IconButton sx={{position: "absolute", top: 0, right: 0}} color="error" onClick={() => {setDeleteImageId(image.id);setDeleteDialog(true)}}><DeleteOutline /></IconButton>
				</ImageListItem>
			)}
			</ImageList>
		</Box>
		<DeleteModal dialogDisplay={deleteDialog} setDialogDisplay={setDeleteDialog} url={"api/delete_kavithai_image?image_id=" + deleteImageId} fetchData={fetchData} />
		<Dialog open={openDialog} onClose={clickCloseDialog}>
			<DialogTitle>Upload Image</DialogTitle>
			{progress && <LinearProgress />}
			<DialogContent>
				<TextField
					type="file"
					name="image"
					onChange={e => setImageList(((e.target as HTMLInputElement).files as FileList))}
					helperText={error}
					inputProps={{multiple: true}}
				 />
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="warning" onClick={clickCloseDialog}>Cancel</Button>
				<Button variant="text" onClick={submitImage} type="submit">Submit</Button>
			</DialogActions>
		</Dialog>
		</>
	)
}