import axios from "axios";

export const ipAddress = "https://agaiapp.in";
// export const production = "http://54.88.250.33:8000/";
axios.defaults.baseURL = ipAddress;
axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.timeout = 30000;

const instance =  axios.create({headers: {"Content-Type": "multipart/form-data"}});

instance.interceptors.request.use(config => {
	config.headers.Authorization = "Bearer "+localStorage.getItem("token");
	return config;
}, error => Promise.reject(error));
instance.interceptors.response.use(config => config, error => {
	if(error.code === "ECONNABORTED" && error.message.includes("timeout")) {
		console.error("Request Timed out !")
		return Promise.reject(error);
	}
});

export const api = () => instance;