import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { Link } from "react-router-dom";
import { DrawerLink, SideNav, State, User, UserTypes } from "./divine-constants";
import { green } from "@mui/material/colors";
import { useContext, useState } from "react";

export default function DrawerAdmin(props: {
	links: DrawerLink[],
	drawerCollapse: SideNav,
	setDrawerCollapse: State<SideNav>
}) {
	const [activeLink, setActiveLink] = useState<number>();
	const {user} = useContext(User);
	function handleMobileNav() {
		if(window.innerWidth > 750) return;
		props.setDrawerCollapse(SideNav.Collapsed);
	}
	return <Drawer sx={{
			"& .MuiDrawer-paper": {
			  	width: props.drawerCollapse,
				"@media only screen and (max-width: 750px)": {
					width: props.drawerCollapse === SideNav.Expanded ? SideNav.Expanded : 0
				}
			},
		  }}>
		  	<List className="sidebar">
			{props.links.map((link, i) => link.access.includes(user?.type as UserTypes) && (
				<Link key={i} to={link.path} onClick={() => {handleMobileNav(); setActiveLink(i)}} style={{backgroundColor: i===activeLink ? green[50] : ""}}>
					<ListItem>
						<ListItemButton>
							<ListItemIcon>{link.icon}</ListItemIcon>
							<ListItemText className='sidebar-link-text parent' primary={link.name} />
						</ListItemButton>
					</ListItem>
				</Link>
			))}
		  	</List>
		</Drawer>
}