import { Toolbar, AppBar, IconButton, Avatar, Menu, MenuItem, Paper, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { AddOutlined, Logout, ManageAccountsOutlined, MenuOutlined, Person } from "@mui/icons-material";
import { SideNav, State, User, UserTypes } from "./divine-constants";
import { useNavigate } from "react-router-dom";
import AddAdminUser from "../admin/AddAdminUser";

function UserMenu(props: {adminAnchorEl: HTMLAnchorElement | null, closeAdminMenu: () => void, isOpen: boolean}) {
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = useState(false);
	const {user, setUser} = useContext(User);
	function handleLogOut() {
		localStorage.clear();
		setUser({
			email: null,
			loggedIn: false,
			type: null
		});
		navigate('/login');
	}
	
	return <Paper>
	<Menu variant="menu" keepMounted anchorEl={props.adminAnchorEl} onClose={props.closeAdminMenu} open={props.isOpen}>
	  <MenuItem onClick={props.closeAdminMenu} style={{display: "flex", paddingBlock: 8}}>
		<div className="profile-image">
		  <Avatar sx={{mr: 2}}/>
		</div>
		<div className="profile-description">
		  <span className='name'>அகை ({user?.type})</span><br />
		  <span className='email'>{user?.email}</span>
		</div>
	  </MenuItem>
	  {user?.type === UserTypes.ADMIN && <MenuItem style={{paddingBlock: 8}} onClick={() => setOpenDialog(true)}><AddOutlined sx={{mr: 2}}/>Add User</MenuItem>}
	  {user?.type === UserTypes.ADMIN && <MenuItem style={{paddingBlock: 8}} component="a" href="/space-members-list"><ManageAccountsOutlined sx={{mr: 2}}/>Manage members</MenuItem>}
	  <MenuItem style={{paddingBlock: 8}} onClick={handleLogOut}><Logout sx={{mr: 2}}/>Sign out</MenuItem>
	</Menu>
	<AddAdminUser openDialog={openDialog} setOpenDialog={setOpenDialog} />
  </Paper>
}

export default function Header(props: {setDrawerCollapse: State<SideNav>}) {
	const [adminAnchorEl, setAdminAnchorEl] = useState<HTMLAnchorElement | null>(null);
	const {user} = useContext(User);
	const theme = useTheme();
	const closeAdminMenu = () => {setAdminAnchorEl(null)}
	const openAdminMenu = (event: React.MouseEvent<HTMLButtonElement>) => {setAdminAnchorEl(event.currentTarget as unknown as HTMLAnchorElement)}
	function toggleDrawer() {
		props.setDrawerCollapse(current => current === SideNav.Collapsed ? SideNav.Expanded : SideNav.Collapsed)
	}
	return (
		<AppBar color={"primary"}>
			<Toolbar>
				<IconButton sx={{color: "white", padding: "8px"}} onClick={toggleDrawer} size="large" style={{marginInline: 8}}><MenuOutlined /></IconButton>
				<img src="/logo192.png" height={50} alt="logo" style={{marginRight: "auto"}} />
				<IconButton sx={{color: "white"}} size="large" onClick={openAdminMenu}><Person /></IconButton>
				<Typography color={"Background"} sx={{
					[theme.breakpoints.down('sm')]: {
						display: "none"
					}
				}}>{user?.email}</Typography>
				<UserMenu adminAnchorEl={adminAnchorEl} isOpen={Boolean(adminAnchorEl)} closeAdminMenu={closeAdminMenu} />
			</Toolbar>
		</AppBar>
	)
}