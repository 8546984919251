import { State } from "./universal/divine-constants";

export function emptyRows(page: number, rowsPerPage: number, n: number): number {
	return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - n) : 0;
}

export function handleSearch<T>(data: T[], query: string): T[] {
	return data.filter(item => 
		Object.values(item as Object)
		.map(v => v?.toString())
		.some((el: string) => el?.toLowerCase().includes(query.toLowerCase()))
	);
}

export function handleImageInput(ev: React.ChangeEvent<HTMLInputElement>, setState: State<File>) {
	setState((ev.target.files as FileList)[0]);
}

export function handleTextChange(ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setState: State<any>) {
	setState((curr: any) => ({...curr, [ev.target.name]: ev.target.value}));
}