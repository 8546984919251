import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, MenuItem, TextField, Box } from "@mui/material";
import { Alert, State, imageTypes } from "../universal/divine-constants";
import { useContext, useState } from "react";
import { handleTextChange } from "../utils";
import { api } from "../apiConfig";
import { FormData } from "./Event";
import { CloudUploadOutlined } from "@mui/icons-material";
import { VisuallyHiddenInput } from "../components/styledComponents";

export default function UpdateEvent(props: {openDialog: boolean, setOpenDialog: State<boolean>, formData: FormData, setFormData: State<FormData>, fetchData: () => Promise<void>}) {
	const [progress, setProgress] = useState<boolean>(false);
	const [error, setError] = useState<FormData>({
		type: " ",
		endDate: " ",
		startDate: " ",
		resultDate: " ",
		title: " ",
		image: " ",
		description: " "
	});
	const {setAlert} = useContext(Alert);
	

	function validate(): boolean {
		let isValid = true;
		if(props.formData.type.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, type: "Please select a type"}));
		} else setError(curr => ({...curr, type: " "}));
		if(props.formData.description.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, description: "Please enter a description"}));
		} else setError(curr => ({...curr, description: " "}));
		if((props.formData.startDate as string).trim() === "") {
			isValid = false;
			setError(curr => ({...curr, startDate: "Please enter a start date"}));
		} else setError(curr => ({...curr, startDate: " "}));
		if((props.formData.endDate as string).trim() === "") {
			isValid = false;
			setError(curr => ({...curr, endDate: "Please enter an end date"}));
		} else setError(curr => ({...curr, endDate: " "}));
		if((props.formData.resultDate as string).trim() === "") {
			isValid = false;
			setError(curr => ({...curr, resultDate: "Please enter a result date"}));
		} else setError(curr => ({...curr, resultDate: " "}));
		if(props.formData.title.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, title: "Please enter a title"}));
		} else setError(curr => ({...curr, title: " "}));
		if((props.formData.image === null || props.formData.image === undefined) && (props.formData.file === undefined || props.formData.file === null)) {
			isValid = false;
			setError(curr => ({...curr, image: "Please upload an image file"}));
		} else setError(curr => ({...curr, image: " "}));
		return isValid
	}
	function addImage(ev: React.ChangeEvent<HTMLInputElement>) {
		let reader = new FileReader();
		reader.readAsDataURL(ev.target.files?.item(0) as File);
		reader.onload = (e: ProgressEvent<FileReader>) => {
			props.setFormData(curr => ({...curr, image: e.target?.result, file: ev.target.files?.item(0)}));
			setError(curr => ({...curr, image: " "}))
		}
	}
	function removeError(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setError(curr => ({...curr, [e.target.name]: " "}));
	}
	function clearAllErrors() {
		setError({
			type: " ",
			endDate: " ",
			startDate: " ",
			resultDate: " ",
			title: " ",
			image: " ",
			description: " "
		});
	}
	const apiMethod = (data: Object) => props.formData.id === -1 ? api().post("api/event/add_event", data) : api().post("api/edit_event_user", data); 
	function handleFormSubmit() {
		setProgress(true);
		if(!validate()) {
			setProgress(false);
			return;
		};
		
		apiMethod({
				event_name: props.formData.title,
				event_type: props.formData.type,
				event_date: props.formData.startDate,
				end_date: props.formData.endDate,
				result_date: props.formData.resultDate,
				event_id: props.formData.id === -1 ? undefined : props.formData.id,
				event_image: props.formData.file ?? "null",
				event_description: props.formData.description
			}).then(res => {
				if(res.data === "added successfully") {
					setAlert({
						show: true,
						message: "Event added successfully",
						severity: "success"
					});
				} else if(res.data.message === "event_update_successfully") {
					setAlert({
						show: true,
						message: "Event updated successfully",
						severity: "success"
					});
				}
			}).catch(() => {
				setAlert({
					show: true,
					message: "Something went wrong. Please try again later.",
					severity: "error"
				});
			}).finally(() => {
				setProgress(false);
				props.setOpenDialog(false);	
				props.fetchData();
			});
	}

	return (
		<Dialog open={props.openDialog} 
		keepMounted={true}
		onClose={() => {props.setOpenDialog(false)}}>
			<DialogTitle>{props.formData.id === -1 ? "Add new" : "Edit"} Event</DialogTitle>
			{progress && <LinearProgress />}
			<DialogContent>
				<form onSubmit={handleFormSubmit}>
					<TextField
						type="text"
						name="title"
						label="Title"
						placeholder="title of the event..."
						helperText={error.title}
						onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
						value={props.formData.title} 
					/>
					<TextField
						select
						name="type"
						label="Event type"
						helperText={error.type}
						onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
						value={props.formData.type} 
					>
						<MenuItem value="shortstory">Short Story</MenuItem>
						<MenuItem value="proverb">Proverbs</MenuItem>
						<MenuItem value="kurunovel">Kuru Novel</MenuItem>
					</TextField>
					<TextField
						type="date"
						name="startDate"
						label="Start date"
						helperText={error.startDate}
						onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
						value={props.formData.startDate} 
					/>
					<TextField
						type="date"
						name="endDate"
						label="End date"
						helperText={error.endDate}
						onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
						value={props.formData.endDate} 
					/>
					<TextField
						type="date"
						name="resultDate"
						label="Result date"
						helperText={error.resultDate}
						onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
						value={props.formData.resultDate} 
					/>
					<TextField
						type="text"
						name="description"
						label="Description"
						placeholder="Type here..."
						helperText={error.description}
						multiline
						onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
						value={props.formData.description} 
						className="takeFullWidth"
					/>
					<Box className="takeFullWidth mui-border" position="relative">
						<img src={props.formData.image as string} style={{width: "100%", filter: "opacity(0.4)"}} alt={"preview"} />
						<Button component="label" startIcon={<CloudUploadOutlined />} color="warning" variant="contained" sx={{
							position: "absolute",
							top: "50%", left: "50%", transform: "translate(-50%, -50%)",
							opacity: "0.95",
							width: "max-content"
						}}>Upload image <VisuallyHiddenInput accept={imageTypes.join(', ')} type="file" onChange={addImage} /></Button>
					</Box>
					<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall css-k2ze6b-MuiFormHelperText-root">{error.image as string}</p>
				</form>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="warning" onClick={() => {clearAllErrors(); props.setOpenDialog(false)}}>Cancel</Button>
				<Button variant="text" onClick={handleFormSubmit}>Submit</Button>
			</DialogActions>
		</Dialog>
	)
}