import { Dialog, DialogTitle, LinearProgress, DialogContent, TextField, DialogActions, Button, Box } from "@mui/material";
import { useState, useContext } from "react";
import { api } from "../apiConfig";
import { State, Alert } from "../universal/divine-constants";
import { handleTextChange } from "../utils";
import { FormData } from "./CookingTips";
import { CloudUploadOutlined } from "@mui/icons-material";
import { VisuallyHiddenInput } from "../components/styledComponents";

export default function UpdateCookingEvent(props: {openDialog: boolean, setOpenDialog: State<boolean>, formData: FormData, setFormData: State<FormData>, fetchData: () => void}) {
	const [progress, setProgress] = useState(false);
	const [error, setError] = useState({
		title: " ",
		description: " ",
		image: " "
	});
	
	const {setAlert} = useContext(Alert);
	function removeError(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
		setError(curr => ({...curr, [e.target.name]: " "}));
	}

	function validate(): boolean {
		let isValid = true;
		if(props.formData.title.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, title: "Please enter a title"}));
		} else setError(curr => ({...curr, title: " "}));
		if(props.formData.description.trim() === "") {
			isValid = false;
			setError(curr => ({...curr, description: "Please enter description"}));
		} else setError(curr => ({...curr, description: " "}));
		if(props.formData.image === null && (props.formData.file === undefined || props.formData.file === null)) {
			isValid = false;
			setError(curr => ({...curr, image: "Please enter an image file"}));
		} else setError(curr => ({...curr, image: " "}));
		return isValid;
	}
	function addImage(ev: React.ChangeEvent<HTMLInputElement>) {
		let reader = new FileReader();
		reader.readAsDataURL(ev.target.files?.item(0) as File);
		reader.onload = (e: ProgressEvent<FileReader>) => {
			props.setFormData(curr => ({
				...curr, 
				image: e.target?.result, 
				file: ev.target.files?.item(0)
			}));
			setError(curr => ({...curr, image: " "}));
		}
	}
	
	const apiMethod = (data: Object) => props.formData.id === -1 ? api().post("api/add_cooking_tips", data) : api().post("api/edit_cooking_tips", data); 
	function handleFormSubmit() {
		setProgress(true);
		if(!validate()) {
			setProgress(false);
			return;
		};
		
		apiMethod({
			cooking_id: props.formData.id === -1 ? undefined : props.formData.id,
			content: props.formData.description,
			name: props.formData.title,
			image: props.formData.file ?? "null"
		}).then(res => {
				if(res.status === 201) {
					if( props.formData.id === -1)
						setAlert({
							show: true,
							message: "Cooking tip added successfully",
							severity: "success"
						});
					else setAlert({
						show: true,
						message: "Cooking tip updated successfully",
						severity: "success"
					});
				}
			}).catch(() => {
				setAlert({
					show: true,
					message: "Something went wrong. Please try again later.",
					severity: "error"
				});
			}).finally(() => {
				setProgress(false);
				props.setOpenDialog(false);	
				props.fetchData();
			});
	}

	return (
		<Dialog open={props.openDialog} onClose={() => props.setOpenDialog(false)}>
			<DialogTitle>Add cooking tip</DialogTitle>
			{progress && <LinearProgress />}
			<DialogContent>
				<TextField
					type="text"
					name="title"
					label="Title"
					value={props.formData.title}
					fullWidth
					placeholder="Tip #1"
					onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
					helperText={error.title}
				/>
				<TextField
					minRows={5}
					maxRows={10}
					type="text"
					name="description"
					multiline
					label="Description"
					value={props.formData.description}
					fullWidth
					placeholder="type here ..."
					onChange={e => {handleTextChange(e, props.setFormData); removeError(e)}}
					helperText={error.description}
				/>
				<Box className="takeFullWidth mui-border" position="relative">
					<img src={props.formData.image as string} style={{width: "100%", filter: "opacity(0.4)"}} alt={"preview"} />
					<Button component="label" startIcon={<CloudUploadOutlined />} color="warning" variant="contained" sx={{
						position: "absolute",
						top: "50%", left: "50%", transform: "translate(-50%, -50%)",
						opacity: "0.95",
						width: "max-content"
					}}>Upload image <VisuallyHiddenInput type="file" onChange={addImage} /></Button>
				</Box>
					<p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall css-k2ze6b-MuiFormHelperText-root">{error.image}</p>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="warning" onClick={() => props.setOpenDialog(false)}>Cancel</Button>
				<Button variant="text" onClick={handleFormSubmit} type="submit">Submit</Button>
			</DialogActions>
		</Dialog>
	)
}