import { Button, CircularProgress, type ButtonPropsColorOverrides, type ButtonPropsVariantOverrides } from "@mui/material";
import { type OverridableStringUnion } from "@mui/types";

interface Props {
	showProgress?: boolean;
	text: string | "Submit" | "Update";
	color?: OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides>;
	variant?: OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
}
export default function SubmitButton(props: Props) {
	return (
		<div style={{display: "grid", placeContent: "end"}}>
			<Button type="submit" variant={props.variant} color={props.color} disabled={props.showProgress}>
				{props.text} {props.showProgress && <>&nbsp; <CircularProgress color="info" variant="indeterminate" size="16px" /></>}
			</Button>
		</div>
	)
}