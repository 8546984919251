import { ThemeProvider, CssBaseline, Box, Paper } from '@mui/material';
import { Alert, AlertMessage, SideNav, User } from './universal/divine-constants';
import { useState, useEffect } from "react";
import { theme } from './theme';
import { TUser } from './universal/divine-constants';
import Router from './Router';
import Header from './universal/Header';
import DrawerAdmin from './universal/DrawerAdmin';
import './App.css';
import { Links } from './links';
import { default as AlertToast } from './components/AlertMessage';

function App() {
  const [user, setUser] = useState<TUser>({
    email: localStorage.getItem("email"),
    loggedIn: Boolean(localStorage.getItem("token")?.split('.').length === 3),
    type: localStorage.getItem("type")
  });
  const [alert, setAlert] = useState<AlertMessage>({title: "", message: "", severity: "success", show: false})
  const [drawerCollapse, setDrawerCollapse] = useState(SideNav.Expanded);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if(alert.show) {
        setAlert({
          title: "",
          message: "",
          severity: "info",
          show: false
        })
      }
    }, 5000);
    return () => clearTimeout(timeout);
  }, [alert.show]);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Alert.Provider value={{alert, setAlert}}>
          <User.Provider value={{user, setUser}}>
            <CssBaseline />
            {user.loggedIn && <Header setDrawerCollapse={setDrawerCollapse} />}
            {user.loggedIn && <DrawerAdmin links={Links} drawerCollapse={drawerCollapse} setDrawerCollapse={setDrawerCollapse} />}
            <Box component="div" className="overlay" sx={{
              "@media only screen and (max-width: 750px)": {
                position: "fixed",
                height: "100vh",
                zIndex: 1200,
                width: drawerCollapse === SideNav.Expanded ? "100vw" : 0,
                transition: "left 300ms ease",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                left: drawerCollapse === SideNav.Expanded ? SideNav.Expanded : 0
              }
            }}/>

            {alert.show && <AlertToast />}

            <Box component="main" 
              style={user.loggedIn ? {
                marginLeft: drawerCollapse,
                padding: "4vmin",
                transition: "margin-left 300ms ease" // animate the main content when drawer toggles
              } : {}} 
              sx={{
                "@media only screen and (max-width: 750px)": {
                  marginLeft: "0 !important",
                }
              }}>

              <Box component={Paper} elevation={0} style={{padding: user.loggedIn ? "2vmin 3vmin" : ""}}>
                <Router />
              </Box>
            </Box>
          </User.Provider>
        </Alert.Provider>
      </div>
    </ThemeProvider>
  );
}

export default App;
