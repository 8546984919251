import { Box, Button, Chip, IconButton, InputAdornment, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from "@mui/material";
import { NotificationsActiveOutlined, PriorityHighOutlined, Search } from "@mui/icons-material";
import { useState, useEffect, useContext } from "react";
import { EditItem, Redbin, ViewItem } from "../components/TableIcons";
import { emptyRows, handleSearch } from "../utils";
import { Link } from "react-router-dom";
import { api } from "../apiConfig";
import { TablePaginationActions } from "../components/Table-Pagination";
import UpdateEvent from "./UpdateEvent";
import ComponentTitle from "../universal/Title";
import DeleteModal from "./DeleteModal";
import { User, UserTypes, Alert } from "../universal/divine-constants";

export interface FormData {
	title: string;
	startDate: string;
	endDate: string;
	resultDate: string;
	type: string,
	description: string
	id?: number,
	image?: string | ArrayBuffer | null,
	file?: File | null,
}

interface TEvent {event_id: number, event_name: string, event_type: string, event_date: string, end_date: string, result_date: string, winners_id: number, event_description: string, event_image: string, end_status: boolean, result_status: boolean, status: boolean, result_today: boolean};

export default function Event() {
	const {user} = useContext(User);
	const [rows, setRows] = useState<TEvent[]>([]);
	const [filteredList, setFilteredList] = useState(rows);
	const {setAlert} = useContext(Alert);
	const [formData, setFormData] = useState<FormData>({
		type: "",
		endDate: "",
		startDate: "",
		resultDate: "",
		title: "",
		description: "",
		image: "",
		id: -1
	})
	const [noResults, setNoResults] = useState(false);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [progress, setProgress] = useState(true);
	const [openDialog, setOpenDialog] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [deleteId, setDeleteId] = useState<number>();
	const eptyRws = emptyRows(page, rowsPerPage, filteredList.length);
	
	const fetchData = async () => await api().get("api/event/event_list").then(res => {
		setRows(res.data);
	}).catch(err => {
		setAlert({
			message: "Unable to fetch data",
			severity: "error",
			show: true
		});
	}).finally(() => setProgress(false));

	const updateClickedRow = (item: TEvent) => {
		setFormData({
			type: item.event_type,
			endDate: item.end_date,
			startDate: item.event_date,
			resultDate: item.result_date,
			title: item.event_name,
			description: item.event_description,
			image: item.event_image,
			id: item.event_id
		});
		setOpenDialog(true);
	}

	const tableSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const next = handleSearch(rows, event.target.value);
		if(next.length <= 0) setPage(0);
		setFilteredList(next);
		setNoResults(next.length <= 0);
	}

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		setFilteredList(rows);
		setPage(0);
	}, [rows]);

	function formatEventTypeStr(str: string) {
		switch(str) {
			case "kurunovel": return "Kuru Novel";
			case "shortstory": return "Short Story";
			case "proverb": return "Proverb";
			default: return "";
		}
	}

	function handlePageChange(_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
		setRowsPerPage(parseInt(event.target.value));
		setPage(0);
	}

	return (
		<>
		<ComponentTitle title="Events" />
		<Box>
			<Box className="table-actions">
				<TextField 
					type="text"
					label="Search"
					required={false}
					InputLabelProps={{shrink: undefined}}
					InputProps={{endAdornment: <InputAdornment position="end"><Search /></InputAdornment>}}
					placeholder="Eg. 5th July"
					onChange={tableSearch}
					helperText={noResults && "No results found"}
					FormHelperTextProps={{
						error: noResults
					}}
				/>
				<Button variant="outlined" onClick={() => {
					setFormData({
						type: "",
						endDate: "",
						startDate: "",
						resultDate: "",
						title: "",
						id: -1,
						description: ""
					});
					setOpenDialog(true)}}>Add Event</Button>
			</Box>
			{progress && <LinearProgress />}
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell />
							<TableCell>S.No</TableCell>
							<TableCell>Title</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Start date</TableCell>
							<TableCell>End date</TableCell>
							<TableCell>Result date</TableCell>
							<TableCell align="center">Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(rowsPerPage > 0
							? filteredList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
							: filteredList
							).map((item, index) => item.status && <TableRow key={index}>
							<TableCell>
								{!item.winners_id && item.result_today && <Tooltip title={"Result is still pending !"}>
									<IconButton color="warning" sx={{
										animation: "pulse 1.5s infinite"
									}}><PriorityHighOutlined /></IconButton>
								</Tooltip>}
							</TableCell>
							<TableCell>{index + 1 + page * rowsPerPage}</TableCell>
							<TableCell sx={{overflowX: "hidden", textOverflow: "ellipsis"}}>{item.event_name}</TableCell>
							<TableCell>{formatEventTypeStr(item.event_type)}</TableCell>
							<TableCell>{item.event_date}</TableCell>
							<TableCell>{item.end_date} &nbsp;&nbsp; {item.end_status && <Chip label="Event has ended" />}</TableCell>
							<TableCell>{item.result_date}</TableCell>
							<TableCell align="center">
								<EditItem onClick={() => updateClickedRow(item)} />
								<Link to={`/event-details?event_id=${item.event_id}&book_type=${item.event_type}`}><ViewItem /></Link>
								<Tooltip title={user?.type !== UserTypes.ADMIN ? "You don't have access to delete" : ""}>
									<span>
										<Redbin disabled={user?.type !== UserTypes.ADMIN} onClick={() => {setDeleteId(item.event_id); setDeleteDialog(true)}} />
									</span>
								</Tooltip>
							</TableCell>
						</TableRow>)}
						{eptyRws > 0 && (
							<TableRow style={{ height: 53 * eptyRws }}>
								<TableCell colSpan={4} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				count={filteredList.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleChangeRowsPerPage}
				ActionsComponent={TablePaginationActions}
			/>
			<UpdateEvent openDialog={openDialog} setOpenDialog={setOpenDialog} formData={formData} setFormData={setFormData} fetchData={fetchData} />
			<DeleteModal dialogDisplay={deleteDialog} setDialogDisplay={setDeleteDialog} url={"api/delete_event_user?event_id=" + deleteId} fetchData={fetchData}/>
		</Box>
		</>
	)
}